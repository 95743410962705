@font-face {
   font-family: "Bahnschrift Regular";
   font-style: normal;
   font-weight: normal;
   src: local("Bahnschrift Regular"), url("/fonts/BAHNSCHRIFT.woff") format("woff");
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#nprogress .bar {
   background: #9e0a0f !important;
}

#nprogress .peg {
   box-shadow: 0 0 10px #9e0a0f, 0 0 5px #9e0a0f;
}

#nprogress .spinner-icon {
   border-top-color: #9e0a0f;
   border-left-color: #9e0a0f;
}

.bgSelect div,
.bgSelect span {
   background-color: #4d3d32 !important;
   color: white;
   border: none !important;
}

.table-resizeable:not(.ei) th:last-child {
   border-right: 1px solid #4d3d32;
}

.tage-local input {
   text-align: center !important;
}
.ant-switch-checked {
   background-color: #9e0b0f !important;
}

.f-white {
   color: #ffff !important;
   letter-spacing: 2px !important;
}
